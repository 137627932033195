import {
    SvgIcon,
} from '@mui/material';

const AuctionetIcon = (props) => {
    return (
        <SvgIcon
            {...props}
            sx = {{
                fontSize: 20,
                marginLeft: '4px',
            }}
        >
            <path
                d = 'M3.684.422C2.398.926 1.137 2.105.547 3.367c-.422.906-.442 1.348-.504 8.34-.063 7.05-.043 7.43.379 8.566.504 1.329 1.621 2.61 2.84 3.2.781.402 1.39.422 8.402.484 8.758.063 9.008.023 10.652-1.621C23.957 20.696 24 20.422 24 12c0-8.316-.063-8.695-1.559-10.21C20.738.085 20.863.104 12.316.061 5.242.02 4.672.043 3.684.422Zm10.273 6.715c1.137.273 1.79.949 1.957 2.043.106.715.234.925.59.968.906.125.613 1.727-.524 2.82-1.812 1.747.84 2.4 3.579.884 1.03-.547 1.347-.653 1.64-.461.969.59.336 1.765-1.347 2.546-.82.38-1.66.567-2.883.633-2.086.145-3.012-.129-4.125-1.117-.887-.8-.801-.82-3.137.293-3.75 1.75-6.129.887-6.129-2.23 0-.907.086-1.012 1.75-2.653 1.262-1.261 2.188-1.957 3.43-2.61 2.398-1.222 3.601-1.476 5.199-1.116Zm0 0'
            />
            <path
                d = 'M11.684 9.348c-1.348.422-2.61 1.136-3.621 2.082-1.22 1.14-1.622 1.898-1.305 2.484.336.633 1.515.445 3.64-.629 2.36-1.16 3.286-1.98 3.286-2.82 0-1.223-.61-1.559-2-1.117Zm0 0'
            />
        </SvgIcon>
    );
};

export default AuctionetIcon;
